<template>
     <base-nav v-model="showMenu" class="navbar-absolute top-navbar" type="white" :transparent="true">
          <div slot="brand" class="navbar-wrapper">
               <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
               <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
                    <button type="button" class="navbar-toggler" @click="toggleSidebar">
                         <span class="navbar-toggler-bar bar1"></span>
                         <span class="navbar-toggler-bar bar2"></span>
                         <span class="navbar-toggler-bar bar3"></span>
                    </button>
               </div>
               <a class="navbar-brand" href="">{{ routeName }}</a>
          </div>

          <ul class="navbar-nav" :class="false ? 'mr-auto' : 'ml-auto'">
               <div class="search-bar input-group" @click="searchModalVisible = true">
                    <!--                     
          <input type="text" class="form-control" placeholder="Search...">
          <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>
        -->
                    <!-- <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal">
                         <i class="tim-icons icon-zoom-split"></i>
                    </button> -->
                    <!-- You can choose types of search input -->
               </div>
               <modal :show.sync="searchModalVisible" class="modal-search" id="searchModal" :centered="false" :show-close="true">
                    <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH" />
               </modal>
               <base-dropdown tag="li" title-tag="a" class="nav-item" title-classes="nav-link" menu-classes="dropdown-navbar">
                    <template slot="title">
                         <div class="photo"><img src="img/mike.jpg" /></div>
                         <div style="display:inline-block;padding-left:10px;padding-right:30px;">{{ $store.state.auth.name }}</div>
                         <b class="caret d-none d-lg-block d-xl-block"></b>
                    </template>

                    <!-- <span class="nav-item dropdown-item">Profile</span> -->

                    <!-- <div class="dropdown-divider"></div> -->
                    <li class="nav-link" @click="logout">
                         <a class="nav-item dropdown-item">Log out</a>
                    </li>
               </base-dropdown>
          </ul>
     </base-nav>
</template>
<script>
     import { CollapseTransition } from "vue2-transitions";
     import { BaseNav, Modal } from "@/components";
     import SidebarToggleButton from "../Layout/SidebarToggleButton";
     import Firebase from "../../firebase.js";
     export default {
          components: {
               SidebarToggleButton,
               CollapseTransition,
               BaseNav,
               Modal,
          },
          computed: {
               routeName() {
                    const { name } = this.$route;
                    return this.capitalizeFirstLetter(name);
               },
          },
          data() {
               return {
                    activeNotifications: false,
                    showMenu: false,
                    searchModalVisible: false,
                    searchQuery: "",
               };
          },
          methods: {
               async logout() {
                    console.log("clicked");
                    await Firebase.logout();
               },

               capitalizeFirstLetter(string) {
                    return string.charAt(0).toUpperCase() + string.slice(1);
               },
               toggleNotificationDropDown() {
                    this.activeNotifications = !this.activeNotifications;
               },
               closeDropDown() {
                    this.activeNotifications = false;
               },
               toggleSidebar() {
                    this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
               },
               hideSidebar() {
                    this.$sidebar.displaySidebar(false);
               },
               toggleMenu() {
                    this.showMenu = !this.showMenu;
               },
          },
     };
</script>
<style scoped>
     .top-navbar {
          top: 0px;
     }
</style>
``
