<template>
     <div class="content">
          <div class="col-md-12 ml-auto mr-auto">
               <!-- <h2 class="text-center">Repository</h2> -->
               <!-- <p class="text-center"></p> -->
          </div>
          <div class="row">
               <div class="text-center">
                    <card
                         class="text-left"
                         :header-classes="{ 'text-right': false }"
                         style="padding-bottom:20px;min-height:200px;padding-top:10px;min-width:350px"
                    >
                         <template slot="header">
                              <h6 class="title ">
                                   Upload Slide

                                   <i slot="title" @click="reset" class="tim-icons icon-refresh-01 float-right" v-if="this.state == 3"></i>
                              </h6>
                         </template>
                         <div>
                              <div>
                                   <div v-if="state == 1">
                                        <file-pond
                                             :class="{ hasfile: hasfile }"
                                             class="filepond"
                                             name="test"
                                             :files="files"
                                             ref="pondsmall"
                                             label-idle="Select or Drop file"
                                             v-bind:allow-multiple="false"
                                             accepted-file-types="application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                             v-on:updatefiles="handleFileUpdate"
                                             v-on:removefile="handleFileRemove"
                                        />
                                   </div>

                                   <div v-if="this.hasfile && this.state == 1">
                                        <button class=" btn btn-primary loadingbtn " :class="{ loading: isLoading }" @click="uploadBtnAction">
                                             <span v-if="state == 1">Upload</span>
                                        </button>
                                   </div>

                                   <div v-else-if="this.state == 2">
                                        <base-progress :label="statuses[statusCode]" :value="parseProgress" value-position="right" type="primary" />
                                   </div>
                                   <div v-else-if="this.state == 3">
                                        <h4>Done</h4>

                                        <!-- <div class="btn-group"> -->
                                        <!-- <router-link class="btn btn-sm btn-primary" :to="{ name: 'TAG', query: { jsonKEY: jsonKEY } }">
                                                  Tag
                                             </router-link>
                                             <button type="button" class="btn btn-sm btn-info btn-outline">
                                                  View
                                             </button>
                                             <button type="button" class="btn btn-sm btn-info btn-outline">
                                                  Download Json
                                             </button> -->
                                        <!-- <button type="button" class="btn btn-sm btn-info btn-outline">
                                                  Download PPT
                                             </button> -->
                                        <!-- </div> -->
                                   </div>
                              </div>
                         </div>
                    </card>
               </div>
          </div>
     </div>
</template>
<script>
     import vueFilePond from "vue-filepond";
     import { BaseProgress } from "src/components/index";
     import axios from "axios";
     import "filepond/dist/filepond.min.css";
     export default {
          name: "starter-page",
          data() {
               return {
                    file: undefined,
                    fileName: undefined,

                    hasfile: false,
                    files: [],

                    state: 1,
                    isLoading: false,
                    progress: 100,

                    fileTypes: ["application/vnd.openxmlformats-officedocument.presentationml.presentation"],

                    statuses: [
                         "Uploading your file...",
                         "Reading the slides...",
                         "Checking all alignments...",
                         "Assessing fonts & colors...",
                         "Creating DSGN score...",
                         "Almost ready, wait for it...",
                         "Polishing the report...",
                         "Your presentation is Ready!",
                    ],
                    statusCode: 0,

                    jsonKEY: undefined,
                    pptKEY: undefined,
                    pptURL: undefined,
                    jsonURL: undefined,
               };
          },
          components: {
               FilePond: vueFilePond(),

               BaseProgress,
          },

          watch: {
               progress(newVal) {
                    // if (newVal === 0) {
                    //      this.statusCode = 0;
                    // } else if (newVal < 50) {
                    //      this.statusCode = 0;
                    // } else if (newVal < 60) {
                    //      this.statusCode = 1;
                    // } else if (newVal < 69) {
                    //      this.statusCode = 2;
                    // } else if (newVal < 77) {
                    //      this.statusCode = 3;
                    // } else if (newVal < 84) {
                    //      this.statusCode = 4;
                    // } else if (newVal < 90) {
                    //      this.statusCode = 5;
                    // } else if (newVal < 100) {
                    //      this.statusCode = 6;
                    // } else if (newVal == 100) {
                    //      this.statusCode = 7;
                    // }
               },
          },

          computed: {
               parseProgress() {
                    return Math.round(this.progress);
               },
          },
          methods: {
               handleFileUpdate(x) {
                    const file = x[0].file;
                    this.file = file;
                    this.files = [file];
                    this.hasfile = true;
                    this.fileName = this.file.name.split(" ").join("_");

                    if (this.fileTypes.indexOf(file.type) == -1) {
                         this.handleFileRemove();
                         this.processError("Please select a .pptx file");
                    }
               },

               handleFileRemove() {
                    this.hasfile = false;
                    this.fileName = undefined;
                    this.file = undefined;
                    this.files = [];
               },
               uploadBtnAction() {
                    if (this.state == 1 && this.hasfile) {
                         this.state = 2;
                         this.isLoading = true;
                         this.progress = 0;
                         this.startUpload();
                    }
               },

               startUpload() {
                    this.getUploadUrl();
               },

               async getUploadUrl() {
                    try {
                         const response = await axios.post(
                              "https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController",
                              {
                                   type: "PUT",
                                   fileName: this.fileName,
                              },
                              {
                                   headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json;charset=UTF-8",
                                   },
                              }
                         );

                         if (!response || response.status !== 200) {
                              this.processError("There was an error while uploading file, Please try again later");
                              return;
                         }
                         const url = response.data.url;

                         this.progress = 0;
                         this.uploadPPT(url);
                    } catch (e) {
                         this.processError("There was an error while uploading file, Please try again later", e);
                    }
               },

               async uploadPPT(url) {



                    
                    try {
                         const response = await axios.put(url, this.file, {
                              headers: {
                                   "Content-Type": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                              },
                              onUploadProgress: (progressEvent) => {
                                   const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 98));
                                   this.progress = uploadPercentage;
                              },
                         });

                         if (!response || response.status !== 200) {
                              this.processError("There was an error while uploading file, Please try again later");
                              return;
                         }
                         console.log(response);

                         this.progress = 98;
                         this.updateDB();
                    } catch (e) {
                         this.processError("There was an error while uploading file, Please try again later", e);
                    }
               },

               async updateDB() {
                    try {
                         const response = await axios.post("https://us-central1-slydeai.cloudfunctions.net/webAPI/api/v1/add", {
                              filename: this.fileName,
                              userEmail: this.$store.state.auth.email,
                         });

                         console.log(response);

                         if (!response || response.status !== 201) {
                              this.processError("There was an error while uploading file, Please try again later");
                              return;
                         }

                         this.progress = 100;
                         this.state = 3;
                    } catch (e) {
                         this.processError("There was an error while uploading file, Please try again later");
                    }
               },

               // async processPPT() {
               //      try {
               //           const incrementor = setInterval(() => {
               //                const range = 40;
               //                const delta = 90 - this.progress;
               //                this.progress = this.progress + (0.5 * delta) / range;

               //                if (this.progress > 89) {
               //                     clearInterval(incrementor);
               //                }
               //           }, 200);

               //           const response = await axios.get(`https://api.slyde.ai/presentation?key=${this.fileName}`);

               //           console.log("processing resp", response);

               //           clearInterval(incrementor);

               //           if (!response || response.status !== 200) {
               //                this.processError("There was an error while processing file, Please try again later", e);
               //                return;
               //           }

               //           const pptName = response.data.ppt;
               //           const jsonName = response.data.json;

               //           if (!jsonName) {
               //                this.processError("failed to process the ppt");
               //                return;
               //           }

               //           this.jsonKEY = jsonName;
               //           this.pptKEY = pptName;
               //           this.progress = 90;
               //           this.getDownloadURL(jsonName, pptName);
               //      } catch (e) {
               //           this.processError("There was an error while processing file, Please try again later", e);
               //      }
               // },

               // async getDownloadURL(jsonName, pptName) {
               //      try {
               //           console.log("jsonName", jsonName);
               //           console.log("pptName", pptName);

               //           const response = await axios.post(
               //                "https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController",
               //                {
               //                     type: "GET",
               //                     jsonName: jsonName,
               //                     pptName: pptName,
               //                },
               //                {
               //                     headers: {
               //                          Accept: "application/json",
               //                          "Content-Type": "application/json;charset=UTF-8",
               //                     },
               //                }
               //           );
               //           console.log("download url response:", response);
               //           if (!response || response.status !== 200) {
               //                this.processError("There was an error while processing file, Please try again later", e);
               //                return;
               //           }

               //           this.jsonURL = response.data.jsonUrl;
               //           this.pptURL = response.data.pptUrl;

               //           console.log(this.jsonURL, this.pptURL);

               //           this.progress = 100;

               //           this.done();
               //      } catch (e) {
               //           this.processError("There was an error while processing file, Please try again later", e);
               //      }
               // },

               done() {
                    try {
                         this.progress = 100;
                         this.isLoading = false;
                         this.state = 3;
                    } catch (e) {
                         this.processError("Couldn't fetch Data", e);
                    }
               },

               processError(errorMessage, e) {
                    this.$notify({
                         message: errorMessage,
                         timeout: 3000,
                         icon: "tim-icons icon-bell-55",
                         horizontalAlign: "right",
                         verticalAlign: "bottom",
                         type: "danger",
                    });
                    this.reset();
               },

               reset() {
                    this.state = 1;
                    this.isLoading = false;
                    this.progress = 100;

                    this.pptURL = undefined;
                    this.jsonURL = undefined;
               },
          },
     };
</script>
<style lang="scss">
     /*================================ filepond ==============================*/

     .filepond.hasfile {
          width: 330px;

          .filepond--panel-root {
               background-color: transparent;
          }
     }
     .filepond {
          width: 300px;

          margin: auto;

          .filepond--drop-label label {
               letter-spacing: 0.8px;
               color: rgba(0, 0, 0, 0.5) !important;
          }

          .filepond--file {
               padding: 13px 15px;
          }
          .filepond--file-info-main {
               font-size: 14px !important;
          }
          .filepond--file-info-sub {
               font-size: 12px !important;
               padding-top: 3px;
          }
          .filepond--file-action-button {
               left: 14px !important;
               top: 16px !important;
          }
          .filepond--root {
               margin-bottom: 0 !important;
          }
     }

     /*================================ Loading Btn ==============================*/

     .loadingbtn.loading {
          cursor: default;
          background-color: rgba(0, 0, 0, 0.05);
          height: 20px;
          border-radius: 5px;

          .loadingContainer {
               .loadingbg {
                    border-radius: 5px;

                    .btncontent {
                         display: none;
                    }
               }
          }
     }

     .loadingbtn:hover {
          box-shadow: 0px 9px 17px 4px rgba(2, 2, 2, 0.05);

          .loadingContainer {
               .loadingbg {
                    background-color: #f1795f;
               }
          }
     }
     .loadingbtn {
          outline: none;
          box-sizing: border-box;
          border: none;
          color: white;
          width: 300px;
          height: 53px;
          font-size: 17px;
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: 7px;
          font-weight: 500;

          padding: 0px;
          line-height: 53px;

          margin: auto;
          display: block;

          .loadingContainer {
               display: block;

               width: 100%;
               height: 100%;
               .loadingbg {
                    transition: all 0.5s;

                    background-color: #f2836b;
                    border-radius: 7px;

                    height: 100%;
                    width: 100%;
               }
          }
     }

     .status {
          display: block;
          margin-top: 15px;
          font-size: 16px;
          letter-spacing: 0.3px;
          font-weight: 400;
          text-align: center;
     }
</style>
