import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
     state: {
          auth: {
               loggedIn: false,
               UID: undefined,
               name: undefined,
               email: undefined,
          },
          repository: [],
     },
     mutations: {},
     actions: {},
     modules: {},
});
