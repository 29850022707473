<template>
     <div>
          <b @click="isModelActive = !isModelActive" style="text-decoration: underline;cursor: pointer">{{ paraItem.text }}</b>

          <b-modal :active.sync="isModelActive" :width="640" scroll="keep">
               <div class="card">
                    <div style="text-align: center;font-size: 1.2em;font-weight: bold;padding-top: 20px">{{ paraItem.text }}</div>
                    <div class="card-content">
                         <div class="content">
                              <table>
                                   <tr>
                                        <th>width</th>
                                        <td>{{ paraItem.width }} px</td>
                                   </tr>

                                   <tr>
                                        <th>height</th>
                                        <td>{{ paraItem.height }} px</td>
                                   </tr>
                                 
                                   <tr>
                                        <th>indent</th>
                                        <td>{{ paraItem.indent }} px</td>
                                   </tr>
                                   <tr>
                                        <th>hasHangingPunctuation</th>
                                        <td>{{ paraItem.hasHangingPunctuation }}</td>
                                   </tr>
                                   <tr>
                                        <th>defaultTabSize</th>
                                        <td>{{ paraItem.defaultTabSize }} px</td>
                                   </tr>
                                   <tr>
                                        <th>isRightToLeft</th>
                                        <td>{{ paraItem.isRightToLeft }}</td>
                                   </tr>
                                   <tr>
                                        <th>text Alignment</th>
                                        <td>{{ paraItem.textAlignment }}</td>
                                   </tr>
                                   <tr>
                                        <th>positionX</th>
                                        <td>{{ paraItem.positionX }} px</td>
                                   </tr>
                                   <tr>
                                        <th>positionY</th>
                                        <td>{{ paraItem.positionY }} px</td>
                                   </tr>

                                   <tr>
                                        <th>marginLeft</th>
                                        <td>{{ paraItem.marginLeft }} px</td>
                                   </tr>

                                   <tr>
                                        <th>marginRight</th>
                                        <td>{{ paraItem.marginRight }} px</td>
                                   </tr>

                                   <tr>
                                        <th>spaceBefore</th>
                                        <td>{{ paraItem.spaceBefore }} px</td>
                                   </tr>

                                   <tr>
                                        <th>spaceAfter</th>
                                        <td>{{ paraItem.spaceAfter }} px</td>
                                   </tr>

                                   <tr>
                                        <th>depth</th>
                                        <td>{{ paraItem.depth }}</td>
                                   </tr>
                                   <tr>
                                        <th>spaceWithin</th>
                                        <td>{{ paraItem.spaceWithin }} px</td>
                                   </tr>

                              
                              </table>
                         </div>
                    </div>
               </div>
          </b-modal>
     </div>
</template>

<script>
     import ImageFormat from './imageFormat';

     export default {
          name: 'textParaFormat',
          components: { ImageFormat },
          data() {
               return {
                    isModelActive: false,
               };
          },
          props: {
               paraItem: Object,
          },
     };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
     th {
          text-transform: capitalize;
     }

     .no-border {
          border: none !important;
     }
</style>
