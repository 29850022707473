<template>
     <div>
          <b @click="isModelActive = !isModelActive" style="text-decoration: underline;cursor: pointer">{{ sectionItem.text }}</b>

          <b-modal :active.sync="isModelActive" :width="640" scroll="keep">
               <div class="card">
                    <div style="text-align: center;font-size: 1.2em;font-weight: bold;padding-top: 20px">{{ sectionItem.text }}</div>
                    <div class="card-content">
                         <div class="content">
                              <table>
                                   <tbody>
                                        <tr>
                                             <th>image</th>
                                             <td><img :src="sectionItem.image" alt="Image" /></td>
                                        </tr>

                                        <tr>
                                             <th>width</th>
                                             <td>{{ sectionItem.width }}</td>
                                        </tr>

                                        <tr>
                                             <th>height</th>
                                             <td>{{ sectionItem.height }}</td>
                                        </tr>
                                        <tr>
                                             <th>positionX</th>
                                             <td>{{ sectionItem.positionX }} px</td>
                                        </tr>

                                        <tr>
                                             <th>positionY</th>
                                             <td>{{ sectionItem.positionY }} px</td>
                                        </tr>
                                        <tr>
                                             <th>fontSize</th>
                                             <td>{{ sectionItem.fontSize }}</td>
                                        </tr>

                                        <tr>
                                             <th>fontBold</th>
                                             <td>{{ sectionItem.fontBold }}</td>
                                        </tr>

                                        <tr>
                                             <th>strikethroughType</th>
                                             <td>{{ sectionItem.strikethroughType }}</td>
                                        </tr>

                                        <tr>
                                             <th>fontItalic</th>
                                             <td>{{ sectionItem.fontItalic }}</td>
                                        </tr>

                                        <tr>
                                             <th>fontUnderline</th>
                                             <td>{{ sectionItem.fontUnderline }}</td>
                                        </tr>

                                        <tr>
                                             <th>normaliseHeight</th>
                                             <td>{{ sectionItem.normaliseHeight }}</td>
                                        </tr>

                                        <tr>
                                             <th>spacing</th>
                                             <td>{{ sectionItem.spacing }}</td>
                                        </tr>

                                        <tr>
                                             <th>symbolFont</th>
                                             <td>{{ sectionItem.symbolFont }}</td>
                                        </tr>

                                        <tr>
                                             <th>latinFont</th>
                                             <td>{{ sectionItem.latinFont }}</td>
                                        </tr>

                                        <tr>
                                             <th>eastAsianFont</th>
                                             <td>{{ sectionItem.eastAsianFont }}</td>
                                        </tr>

                                        <tr>
                                             <th>languageId</th>
                                             <td>{{ sectionItem.languageId }}</td>
                                        </tr>

                                        <tr>
                                             <th>isHardUnderlineLine</th>
                                             <td>{{ sectionItem.isHardUnderlineLine }}</td>
                                        </tr>

                                        <tr>
                                             <th>textCapType</th>
                                             <td>{{ sectionItem.textCapType }}</td>
                                        </tr>

                                        <tr>
                                             <th>Has effects</th>
                                             <td>{{ sectionItem.effects.hasNoEffects }}</td>
                                        </tr>

                                        <tr class="no-border">
                                             <th class="no-border">Underline</th>
                                             <td class="no-border">{{ sectionItem.underline.style > 0 }}</td>
                                        </tr>

                                        <tr class="no-border">
                                             <th class="no-border">underline width</th>
                                             <td class="no-border">{{ sectionItem.underline.width }} px</td>
                                        </tr>
                                        <tr class="no-border">
                                             <th class="no-border">underline dash</th>
                                             <td class="no-border">{{ sectionItem.underline.dashStyle }}</td>
                                        </tr>

                                        <tr class>
                                             <th class>underline alignment</th>
                                             <td class>{{ sectionItem.underline.alignment }}</td>
                                        </tr>

                                        <tr>
                                             <th class="no-border">is hightlighted</th>
                                             <td class="no-border">{{ sectionItem.isHighlighted }}</td>
                                        </tr>
                                        <tr class="no-border">
                                             <th class="no-border">fill Type</th>
                                             <td class="no-border">{{ sectionItem.textFill.fillType }}</td>
                                        </tr>

                                        <tr v-if="sectionItem.textFill.fillType === 'solid'">
                                             <th>fill Color</th>
                                             <td>
                                                  <div
                                                       :style="{ backgroundColor: sectionItem.textFill.solidColor }"
                                                       style="width: 50px;height: 50px;border: 1px solid black"
                                                  ></div>
                                             </td>
                                        </tr>

                                        <tr v-else-if="sectionItem.textFill.fillType === 'picture'">
                                             <th>fill picture</th>
                                             <td>
                                                  <image-format :image-data="sectionItem.textFill.pictureFormat"></image-format>
                                             </td>
                                        </tr>

                                        <tr v-if="sectionItem.isHighlighted != 'false'">
                                             <th>highlightColor</th>
                                             <td>
                                                  <div
                                                       :style="{ backgroundColor: sectionItem.highlightColor }"
                                                       style="width: 50px;height: 50px;border: 1px solid black"
                                                  ></div>
                                             </td>
                                        </tr>

                                        
                                        

                                   </tbody>
                              </table>
                         </div>
                    </div>
               </div>
          </b-modal>
     </div>
</template>

<script>
     export default {
          name: 'textParaFormat',
          data() {
               return {
                    isModelActive: false,
               };
          },
          props: {
               sectionItem: Object,
          },

          mounted() {
               console.log(this.sectionItem);
          },
     };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
     .no-border {
          border: none !important;
     }
     th {
          text-transform: capitalize;
     }
</style>
