<template>
  <div>
    <img
      :src="imageData.image"
      alt
      :width="imageData.width"
      class="thumbnailImage"
      @click="isModelActive=!isModelActive"
      style="cursor: pointer"
    />

    <b-modal :active.sync="isModelActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-image">
          <figure class="image is-4by3">
            <img alt="Image" :src="imageData.image" :width="imageData.width" />
          </figure>
        </div>
        <div class="card-content">
          <div class="content">
            <table>
              <tr>
                <th>type</th>
                <td>{{imageData.imageType}}</td>
              </tr>
              <tr>
                <th>width</th>
                <td>{{imageData.width}} px</td>
              </tr>
              <tr>
                <th>height</th>
                <td>{{imageData.height}} px</td>
              </tr>
              <tr>
                <th>positionX</th>
                <td>{{imageData.positionX}} px</td>
              </tr>
              <tr>
                <th>positionY</th>
                <td>{{imageData.positionY}} px</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "imageFormat",
  data() {
    return {
      isModelActive: false,
    };
  },
  props: {
    imageData: Object,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
th {
  text-transform: capitalize;
}
</style>
