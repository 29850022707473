<template>
     <div class="content">
          <tabs type="primary">
               <tab-pane label="Profile">
                    Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed
                    base benefits. <br /><br />
                    Dramatically visualize customer directed convergence without revolutionary ROI.
               </tab-pane>

               <tab-pane label="Settings">
                    Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas.
                    <br /><br />Dramatically maintain clicks-and-mortar solutions without functional solutions.
               </tab-pane>

               <tab-pane label="Options">
                    Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service
                    with robust ideas. <br /><br />Dynamically innovate resource-leveling customer service for state of the art customer service.
               </tab-pane>
          </tabs>
     </div>
</template>
<script>
     import { TabPane, Tabs } from "src/components";

     export default {
          name: "starter-page",
          data() {
               return {};
          },
          components: {
               TabPane,
               Tabs,
             
          },
     };
</script>
<style></style>
