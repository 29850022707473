import { firebase } from "@firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

import store from "./store/store.js";

const firebaseConfig = {
     apiKey: "AIzaSyDv8jJieBy-_V2qCquk9HxDxtFDdARL_0Q",
     authDomain: "slydeai.firebaseapp.com",
     databaseURL: "https://slydeai.firebaseio.com",
     projectId: "slydeai",
     storageBucket: "slydeai.appspot.com",
     messagingSenderId: "974034917356",
     appId: "1:974034917356:web:bec5e0828a280a06f292c9",
};
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const adminCollection = db.collection("admins");
const repository = db.collection("repository");
//methods

const login = function() {
     const provider = new firebase.auth.GoogleAuthProvider();
     firebase
          .auth()
          .signInWithPopup(provider)
          .then(function(result) {})
          .catch(function(error) {
               const errorCode = error.code;
               const errorMessage = error.message;
               const email = error.email;
               const credential = error.credential;
               console.log(errorCode, errorMessage, email, credential);
          });
};

const logout = function() {
     firebase
          .auth()
          .signOut()
          .then(function() {
               store.state.auth.loggedIn = false;
               store.state.auth.type = undefined;
               store.state.auth.UID = undefined;
               store.state.auth.name = undefined;
               store.state.auth.email = undefined;
          })
          .catch(function(error) {
               console.log(error);
          });
};

repository.orderBy("updated", "desc").onSnapshot((snapshot) => {
     store.state.repository = [];
     snapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;

          item.updated = item.updated.toDate();
          if (item.created) item.created = item.created.toDate();

          store.state.repository.push(item);
     });
});

export default {
     db,
     auth,
     adminCollection,
     repository,
     login,
     logout,
};
