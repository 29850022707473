<template>
     <div class="content">
          <div style="width:300px;margin:auto;" v-if="loading.loading || loading.progress < 100">
               <base-progress label="Downloading JSON" :value="loading.progress" value-position="right" type="primary" />
          </div>

          <div v-else>
               <base-button type="info" style="float:right" @click="save" v-if="this.progress == 0 || this.progress == 100">
                    Save
               </base-button>
               <span
                    v-else
                    style="float:right;color:white;display:inline-block; margin-top:4px;border:1px solid white;border-radius:3px;width:115px;line-height:38px;text-align:center"
               >
                    Saving {{ this.progress }}%
               </span>
               <div class="content sort">
                    <h3 class="text-center">SORT</h3>
                    <div class="row">
                         <div class="col col-3 slide-thumbnail-container">
                              <template v-for="(slide, index) in pptJSON.slides">
                                   <div class="slide-thumbnail " :key="index" v-if="!slide.sorted">
                                        <img draggable @dragstart="startDrag($event, index)" v-bind:src="slide.image" /></div
                              ></template>
                         </div>

                         <div class="col  dropzone-container  ">
                              <div class="row">
                                   <div
                                        class="col-sm-3 dropzone"
                                        v-for="(category, index) in slideTypes"
                                        :key="index"
                                        @drop="onDrop($event, index)"
                                        @dragover.prevent
                                        @dragenter.prevent
                                   >
                                        <div class="card ">
                                             <div class="card-body">
                                                  <div>
                                                       <span
                                                            style="float:right"
                                                            class="addnew"
                                                            @click="addnew(category, index)"
                                                            v-if="category.parent"
                                                            >+</span
                                                       >
                                                       <h3>
                                                            {{ category.title }}
                                                            <span class="number" v-if="!category.parent">{{ category.number }}</span>
                                                       </h3>
                                                       <template v-for="(slide, index) in pptJSON.slides">
                                                            <div
                                                                 class="slide-thumbnail"
                                                                 v-if="slide.sorted && slide.category === category.title"
                                                                 :key="index"
                                                            >
                                                                 <img
                                                                      @click="tag(index)"
                                                                      draggable
                                                                      @dragstart="startDrag($event, index)"
                                                                      v-bind:src="slide.image"
                                                                 /></div
                                                       ></template>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="content tagging" style="margin-top:50px;">
                    <h3 class="text-center">
                         TAG
                    </h3>
                    <div class="row">
                         <div class="col col-3 slide-thumbnail-container ">
                              <template v-for="(slide, index) in pptJSON.slides">
                                   <div class="slide-thumbnail " :key="index">
                                        <img
                                             @click="tag(index)"
                                             draggable
                                             @dragstart="startDrag($event, index)"
                                             v-bind:src="slide.image"
                                             :class="{ tagged: slide.tagged }"
                                        /></div
                              ></template>
                         </div>

                         <div class="col">
                              <div class="content" v-if="currentSlide !== undefined">
                                   <img class="thumbnailImage" v-bind:src="currentSlide.image" />

                                   <tabs type="primary" :vertical="false" :centered="true" :square="false" class="mt-4">
                                        <tab-pane label="Text">
                                             <table
                                                  class="detailsTable mb-5"
                                                  v-for="(textSectionItem, index1) in currentSlide.textItems"
                                                  :key="index1"
                                             >
                                                  <tr>
                                                       <th colspan="2" style="font-size: 1.2em;border-right: none">TextFrame #{{ index1 + 1 }}</th>
                                                  </tr>

                                                  <tr v-for="(textParaItem, index2) in textSectionItem.paragraphs" :key="index2">
                                                       <!-- <th>Paragraph #{{ index2 + 1 }}</th> -->
                                                       <td>
                                                            {{ textParaItem.text }}
                                                       </td>

                                                       <td>
                                                            <div class="tagOptions">
                                                                 <template v-for="(item, index) in textTags">
                                                                      <base-radio
                                                                           :name="item"
                                                                           v-model="textParaItem.tag"
                                                                           :key="index"
                                                                           type="is-danger is-light is-outlined"
                                                                      >
                                                                           {{ item }}
                                                                      </base-radio>
                                                                 </template>
                                                            </div>
                                                       </td>
                                                  </tr>
                                             </table>
                                        </tab-pane>

                                        <tab-pane label="Image">
                                             <table class="detailsTable mb-5" v-for="(imageItem, index) in currentSlide.imageItems" :key="index">
                                                  <tr>
                                                       <th colspan="2" style="font-size: 1.2em;border-right: none">Image #{{ index1 + 1 }}</th>
                                                  </tr>
                                                  <tr>
                                                       <td>
                                                            <img :src="imageItem.image.image" alt="" style="max-width:400px ;height:auto" />
                                                       </td>
                                                       <td>
                                                            <div class="tagOptions">
                                                                 <template v-for="(item, index) in imageTags">
                                                                      <base-radio :name="item" v-model="imageItem.tag" :key="index">
                                                                           {{ item }}
                                                                      </base-radio>
                                                                 </template>
                                                            </div>
                                                       </td>
                                                  </tr>
                                             </table>
                                        </tab-pane>

                                        <tab-pane label="Charts"> </tab-pane>
                                   </tabs>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
     import axios from "axios";

     import { BaseProgress, BaseRadio } from "src/components/index";
     import { Modal } from "src/components";
     import { TabPane, Tabs } from "src/components";
     import imageFormat from "../Components/imageFormat";
     import textParaFormat from "../Components/textParaFormat";
     import textSectionFormat from "../Components/textSectionFormat";
     import { xhr } from "d3";
     export default {
          name: "starter-page",
          data() {
               return {
                    pptJSON: undefined,

                    loading: {
                         loading: false,
                         progress: 0,
                    },
                    currentSlide: undefined,
                    slideTypes: [
                         { title: "Deck Title", number: 1, parent: true },
                         { title: "Section Header", number: 1, parent: true },

                         { title: "Headline with Text Content", number: 1, parent: true },
                         { title: "Text Content Only (no headline)", number: 1, parent: true },

                         { title: "Headline with N Columns", number: 1, parent: true },
                         { title: "N Columns Content Only (no headline)", number: 1, parent: true },

                         { title: "Headline with Image / Image with Caption", number: 1, parent: true },
                         { title: "Image / Image with Caption only (no headline)", number: 1, parent: true },

                         { title: "Headline with Chart / Chart with Caption", number: 1, parent: true },
                         { title: "Chart / Chart With Caption only (no headline)", number: 1, parent: true },

                         { title: "Headline with Mixed Content (Table / Chart / Text)", number: 1, parent: true },
                         { title: "Mixed content only (no headline)", number: 1, parent: true },

                         { title: "Headline with smart art", number: 1, parent: true },
                         { title: "Smart art only  (no headline)", number: 1, parent: true },

                         { title: "Headline with Table", number: 1, parent: true },
                         { title: "Table only (no headline)", number: 1, parent: true },

                         { title: "Quote", number: 1, parent: true },
                         { title: "Big Number", number: 1, parent: true },
                         { title: "Contact / Thanks", number: 1, parent: true },
                         { title: "Miscellaneous", number: 1, parent: true },
                    ],

                    textTags: [
                         "Slide Headline / Header",
                         "Footer / Copyright",
                         "Page Number",
                         "List / Bullets",
                         "Caption for Chart / Image / Smart Art",
                         "Column Header",
                         "Quote",
                         "Big Number",
                         "References",
                         "Paragraph text",
                         "Other text content",
                    ],

                    imageTags: [
                         "Background image",
                         "Company Logo",
                         "Other Logos (Partners / Clients / Others)",
                         "Foreground / Main Image",
                         "Chart as image",
                         "Accent / Highlight image",
                    ],

                    chartTags: [
                         "Bar - 2D/3D",
                         "Column - 2D/3D",
                         "Line - 2D/3D/Area",
                         "Pie - 2D/3D/Doughnut",
                         "Hierarchy - Tree/Sunburst",
                         "XY - Scatter/Bubble",
                         "Histogram - Barchart/Box and Whisker",
                         "Combo - Bar & Line / Bar & Area",
                         "Waterfall - Funnel / Stock",
                    ],
                    activeTab: 0,
                    tagModal: false,

                    currentIndex: 0,
                    // slides: [],
                    // sort: [],

                    docID: undefined,
                    JSONKEY: undefined,
                    alreadyTagged: false,

                    progress: 0,
               };
          },

          async mounted() {
               this.docID = this.$route.query.id;
               this.JSONKEY = this.$route.query.jsonKEY;
               this.alreadyTagged = this.$route.query.alreadyTagged;

               let downloadURL = await this.getDownloadURL(this.JSONKEY);
               if (!downloadURL) {
                    this.processError("file not found");
                    return;
               }

               await this.fetchData(downloadURL);

               if (!this.pptJSON) {
                    this.processError("Could not fetch json");
                    return;
               }

               if (this.pptJSON.hasCustomSlideTypes) {
                    this.slideTypes = this.pptJSON.customSlideTypes;
               }

               console.log(this.pptJSON);
          },
          components: {
               BaseProgress,
               Modal,
               TabPane,
               Tabs,
               BaseRadio,

               imageFormat,
               textSectionFormat,
               textParaFormat,
               [TabPane.name]: TabPane,
          },

          watch: {
               progress(newVal) {
                    // if (newVal === 0) {
                    //      this.statusCode = 0;
                    // } else if (newVal < 50) {
                    //      this.statusCode = 0;
                    // } else if (newVal < 60) {
                    //      this.statusCode = 1;
                    // } else if (newVal < 69) {
                    //      this.statusCode = 2;
                    // } else if (newVal < 77) {
                    //      this.statusCode = 3;
                    // } else if (newVal < 84) {
                    //      this.statusCode = 4;
                    // } else if (newVal < 90) {
                    //      this.statusCode = 5;
                    // } else if (newVal < 100) {
                    //      this.statusCode = 6;
                    // } else if (newVal == 100) {
                    //      this.statusCode = 7;
                    // }
               },
          },
          methods: {
               async getDownloadURL(key) {
                    try {
                         let payload;
                         console.log("hellow", key);

                         if (!this.alreadyTagged) {
                              payload = {
                                   type: "GET",
                                   jsonName: key,
                              };
                         } else {
                              payload = {
                                   type: "GETOBJ",
                                   fileName: `uploads/${key}`,
                              };
                         }

                         const response = await axios.post(
                              "https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController",
                              payload,
                              {
                                   headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json;charset=UTF-8",
                                   },
                              }
                         );
                         console.log(response);

                         if (!response || response.status !== 200) {
                              this.processError("There was an error while processing file, Please try again later");
                              return false;
                         }

                         if (!this.alreadyTagged) {
                              return response.data.jsonUrl;
                         } else {
                              return response.data.fileUrl;
                         }
                    } catch (e) {
                         console.log(e.message);
                         this.processError("error");
                    }
               },

               processError(errorMessage) {
                    this.$notify({
                         message: errorMessage,
                         timeout: 3000,
                         icon: "tim-icons icon-bell-55",
                         horizontalAlign: "right",
                         verticalAlign: "bottom",
                         type: "danger",
                    });
               },

               async fetchData(URL) {
                    try {
                         this.loading.loading = true;
                         let response = await axios.get(URL, {
                              onDownloadProgress: (progressEvent) => {
                                   let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                   this.loading.progress = progress;
                              },
                         });

                         if (response && response.status === 200) {
                              if (typeof response.data == "string") {
                                   this.pptJSON = JSON.parse(response.data);
                              } else {
                                   this.pptJSON = response.data;
                              }

                              this.currentSlide == this.pptJSON.slides[0];
                         }
                    } catch (e) {
                         this.loading.loading = false;
                         this.processError("Could not process pptJSON");
                    }

                    this.loading.loading = false;
               },

               startDrag: (evt, id) => {
                    evt.dataTransfer.dropEffect = "move";
                    evt.dataTransfer.effectAllowed = "move";
                    evt.dataTransfer.setData("itemID", id);
               },
               onDrop(evt, dropzoneId) {
                    const slideId = parseInt(evt.dataTransfer.getData("itemID"));
                    this.pptJSON.slides[slideId].sorted = true;
                    this.pptJSON.slides[slideId].category = this.slideTypes[dropzoneId].title;

                    console.log(this.pptJSON.slides[slideId]);

                    this.pptJSON.slides.push([]);
                    this.pptJSON.slides.pop();

                    // const d((item) => item.id == itemID);
                    // item.list = list;
               },

               tag(index) {
                    this.currentSlide = this.pptJSON.slides[index];
               },

               save() {
                    this.getUploadUrl();
               },

               async getUploadUrl() {
                    console.log(this.json);
                    try {
                         const response = await axios.post(
                              "https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController",
                              {
                                   type: "PUT",
                                   fileName: this.JSONKEY,
                              },
                              {
                                   headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json;charset=UTF-8",
                                   },
                              }
                         );

                         if (!response || response.status !== 200) {
                              this.processError("There was an error while uploading JSON, Please try again later");
                              return;
                         }
                         const url = response.data.url;
                         console.log("test", url);
                         this.progress = 0;
                         this.uploadJSON(url);
                    } catch (e) {
                         this.processError("There was an error while uploading JSON, Please try again later", e);
                    }
               },

               async uploadJSON(url) {
                    const blob = new Blob([JSON.stringify(this.pptJSON)], { type: "text/json" });
                    const file = new FormData();
                    file.append("file", blob);
                    try {
                         const response = await axios.put(url, JSON.stringify(this.pptJSON), {
                              headers: {
                                   "Content-Type": "application/json",
                              },
                              onUploadProgress: (progressEvent) => {
                                   const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 98));
                                   this.progress = uploadPercentage;
                              },
                         });

                         if (!response || response.status !== 200) {
                              this.processError("There was an error while uploading file, Please try again later");
                              return;
                         }
                         console.log(response);
                         this.progress = 98;
                         this.updateDB();
                    } catch (e) {
                         this.processError("There was an error while uploading file, Please try again later");
                    }
               },

               async updateDB() {
                    try {
                         const response = await axios.post("https://us-central1-slydeai.cloudfunctions.net/webAPI/api/v1/savetags", {
                              id: this.docID,
                         });

                         if (!response || response.status !== 200) {
                              this.processError("There was an error while updating records, Please try again later");
                              return;
                         }

                         this.progress = 100;
                    } catch (e) {
                         this.processError("There was an error while updating records, Please try again later");
                    }
               },

               addnew(x, index) {
                    let item = {
                         title: x.title + "_" + x.number++,
                         number: x.number,
                         parent: false,
                    };

                    this.slideTypes.splice(index + x.number, 0, item);

                    this.pptJSON.hasCustomSlideTypes = true;
                    this.pptJSON.customSlideTypes = this.slideTypes;
               },
          },
          computed: {},
     };
</script>
<style lang="scss" scoped>
     .addnew {
          font-size: 20px;
          width: 20px;
          position: relative;
          top: -10px;
          right: -10px;
          cursor: pointer;
     }
     .addnew:hover {
          color: white;
     }
     .slide-thumbnail-container {
          overflow-y: auto;
          display: block;
          height: auto;
          max-height: calc(100vh - 100px);

          max-width: 400px;
          .slide-thumbnail {
               display: block;
               margin: 10px;
               width: 100%;
               padding: 3px;
          }
     }

     .sort {
          .dropzone-container {
               overflow-y: auto;
               display: block;
               max-height: calc(100vh - 100px);

               .dropzone {
                    padding: 10px;

                    width: 100%;

                    .card {
                         min-height: 100px;
                         margin-bottom: 0;

                         h3 {
                              font-size: 15px;
                              font-weight: bold;
                              margin-bottom: 10px;
                         }
                         .slide-thumbnail {
                              width: 50%;
                              display: inline-block;
                              padding: 3px;
                              img {
                                   cursor: move;

                                   // border: 2px solid red;
                                   padding: 1px;
                                   width: 100%;
                                   height: auto;
                              }
                              img.tagged {
                                   // border: 2px solid green;
                              }
                         }
                    }
               }
          }
     }
     .tagging {
          .slide-thumbnail-container {
               img {
                    width: 100%;
                    padding: 1px;
                    border-width: 4px;
                    height: auto;
                    cursor: pointer;
                    // border-color: red;
                    // border-style: dotted;
               }
               img.tagged {
                    border: 4px;
                    border-color: green;
               }

               img.activeTagged {
                    border-style: solid;
               }
          }
          img.thumbnailImage {
               display: block;
               width: 100%;
               max-width: 600px;

               height: auto;
               margin: auto;
          }

          table {
               padding: 30px;
               tr {
                    th {
                         padding: 30px 0;
                    }

                    td {
                         padding: 40px;
                         border: 1px solid grey;
                    }
               }
          }
     }

     .number {
          background: coral;
          padding: 0 10px;
          border-radius: 20px;
          display: inline-block;
     }
</style>
<style lang="scss">
     .tagging {
          .tablist,
          .nav-item {
               justify-content: center;
               align-items: center;
          }
     }
</style>
