<template>
     <div class="content">
          <div class="col-md-12 ml-auto mr-auto">
               <!-- <h2 class="text-center">Repository</h2> -->
               <!-- <p class="text-center"></p> -->
          </div>
          <div class="row mt-0">
               <div class="col-12">
                    <card card-body-classes="table-full-width">
                         <!-- <h4 slot="header" class="card-title">Repository</h4> -->
                         <div class="p-10">
                              <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                                   <el-select class="select-primary mb-3 pagination-select" v-model="status.value" placeholder="Per page">
                                        <el-option class="select-primary" v-for="item in status.options" :key="item" :label="item" :value="item">
                                        </el-option>
                                   </el-select>

                                   <base-input>
                                        <el-input
                                             type="search"
                                             class="mb-3 search-input"
                                             clearable
                                             prefix-icon="el-icon-search"
                                             placeholder="Search records"
                                             v-model="searchQuery"
                                             aria-controls="datatables"
                                        >
                                        </el-input>
                                   </base-input>
                              </div>

                              <el-table :data="queriedData">
                                   <el-table-column :min-width="150" align="center" label="Email">
                                        <div slot-scope="props" class="img-container">
                                             <div v-if="props.row.userEmail">{{ props.row.userEmail }}</div>
                                        </div>
                                   </el-table-column>

                                   <el-table-column :min-width="135" align="center" label="File name">
                                        <div slot-scope="props" v-if="props.row.filename">
                                             {{ props.row.filename }}
                                        </div>
                                   </el-table-column>
                                   <el-table-column :min-width="135" align="center" label="Created ">
                                        <div slot-scope="props" v-if="props.row.created">
                                             {{ props.row.created | toAgo }} ago

                                             <div style="font-size:10px !important">
                                                  {{ props.row.created.toLocaleString() }}
                                             </div>
                                        </div>
                                   </el-table-column>
                                   <el-table-column :min-width="135" align="center" label="Last UPdated">
                                        <div slot-scope="props" v-if="props.row.updated">
                                             {{ props.row.updated | toAgo }} ago

                                             <div style="font-size:10px !important">
                                                  {{ props.row.updated.toLocaleString() }}
                                             </div>
                                        </div>
                                   </el-table-column>

                                   <el-table-column :min-width="155" align="center" label="Status">
                                        <div slot-scope="props">
                                             <div v-if="props.row.statusCode">
                                                  <span v-if="props.row.statusCode == 1">
                                                       <span class="el-tag el-tag--info el-tag--small el-tag--dark"> UNPROCESSED </span>
                                                  </span>
                                                  <span v-if="props.row.statusCode == -1">
                                                       <span class="el-tag el-tag--warning el-tag--small el-tag--dark"> ERROR EXTRACTING JSON </span>
                                                  </span>

                                                  <span v-if="props.row.statusCode == 2">
                                                       <span class="el-tag el-tag--info el-tag--small el-tag--light"> EXTRACTING JSON </span>
                                                  </span>

                                                  <span v-if="props.row.statusCode == 3">
                                                       <span class="el-tag el-tag--success el-tag--small el-tag--light"> JSON EXTRACTED </span>
                                                  </span>

                                                  <span v-if="props.row.statusCode == 4">
                                                       <span class="el-tag el-tag--success el-tag--small el-tag--light"> TAGGED </span>
                                                  </span>
                                             </div>
                                        </div>
                                   </el-table-column>

                                   <el-table-column :min-width="450" align="right" label="Actions">
                                        <div slot-scope="props">
                                             <div class="btn-group">
                                                  <!-- <button
                                                       class="btn btn-sm btn-primary"
                                                       v-if="props.row.statusCode == 3"
                                                       @click="tag(props.row.JSONKEY)"
                                                  >
                                                       Tag <i class="tim-icons  icon-tag"></i>
                                                  </button> -->

                                                  <router-link
                                                       class="btn btn-sm btn-primary"
                                                       v-if="props.row.statusCode == 3"
                                                       :to="{
                                                            name: 'TAG',
                                                            query: { jsonKEY: props.row.JSONKEY, id: props.row.id, alreadyTagged: false },
                                                       }"
                                                  >
                                                       TAG
                                                  </router-link>

                                                  <router-link
                                                       class="btn btn-sm btn-primary"
                                                       v-if="props.row.statusCode == 4"
                                                       :to="{
                                                            name: 'TAG',
                                                            query: { jsonKEY: props.row.JSONKEY, id: props.row.id, alreadyTagged: true },
                                                       }"
                                                  >
                                                       EDIT TAGS
                                                  </router-link>

                                                  <button
                                                       type="button"
                                                       class="btn btn-sm btn-info btn-outline"
                                                       @click="downloadOriginalPPT(props.row.filename)"
                                                  >
                                                       PPT
                                                  </button>
                                                  <button
                                                       v-if="props.row.statusCode == 4"
                                                       type="button"
                                                       class="btn btn-sm btn-info btn-outline"
                                                       @click="downloadTaggedJSON(props.row.JSONKEY)"
                                                  >
                                                       TAGGED JSON
                                                  </button>
                                                  <!-- <button
                                                       v-if="props.row.statusCode > 1"
                                                       type="button"
                                                       class="btn btn-sm btn-info btn-outline"
                                                       @click="downloadPPT(props.row.filename)"
                                                  >
                                                       Original PPT
                                                  </button> -->
                                             </div>
                                        </div>
                                   </el-table-column>
                              </el-table>
                         </div>
                         <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                              <div class="">
                                   <p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
                              </div>
                              <base-pagination
                                   class="pagination-no-border"
                                   v-model="pagination.currentPage"
                                   :per-page="pagination.perPage"
                                   :total="total"
                              >
                              </base-pagination>
                         </div>
                    </card>

                    <div v-for="(doc, index) in $store.state.repository" :key="index"></div>
               </div>
          </div>
     </div>
</template>
<script>
     import { Table, TableColumn, Select, Option } from "element-ui";
     import { BasePagination } from "src/components";
     import users from "../Tables/users";
     import Fuse from "fuse.js";
     import swal from "sweetalert2";
     import axios from "axios";

     export default {
          components: {
               BasePagination,
               [Select.name]: Select,
               [Option.name]: Option,
               [Table.name]: Table,
               [TableColumn.name]: TableColumn,
          },
          computed: {
               /***
                * Returns a page from the searched data or the whole data. Search is performed in the watch section below
                */
               queriedData() {
                    let result = this.filteredData;

                    if (this.searchedData.length > 0) {
                         result = this.searchedData;
                    }
                    return result.slice(this.from, this.to);
               },
               to() {
                    let highBound = this.from + this.pagination.perPage;
                    if (this.total < highBound) {
                         highBound = this.total;
                    }
                    return highBound;
               },
               from() {
                    return this.pagination.perPage * (this.pagination.currentPage - 1);
               },
               total() {
                    return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
               },

               tableData() {
                    return this.$store.state.repository;
               },

               filteredData() {
                    let results = [];
                    let val = this.status.value;
                    if (val == "ALL") {
                         return this.tableData;
                    }

                    // ["ALL", "CORRECT", "INCORRECT", "INCORRECT AND EXIST IN DB", "UNTAGGED"],
                    else if (val == "UNPROCESSED") {
                         results = this.tableData.filter((item) => {
                              return item.statusCode == 1;
                         });
                    } else if (val == "ERROR EXTRACTING JSON") {
                         results = this.tableData.filter((item) => {
                              return item.statusCode == -1;
                         });
                    } else if (val == "EXTRACTING JSON") {
                         results = this.tableData.filter((item) => {
                              return item.statusCode == 2;
                         });
                    } else if (val == "JSON EXTRACTED") {
                         results = this.tableData.filter((item) => {
                              return item.statusCode == 3;
                         });
                    } else if (val == "TAGGED") {
                         results = this.tableData.filter((item) => {
                              return item.statusCode == 4;
                         });
                    }

                    return results;
               },
          },
          data() {
               return {
                    pagination: {
                         perPage: 100,
                         currentPage: 1,
                         perPageOptions: [5, 10, 25, 50],
                         total: 0,
                    },

                    status: {
                         value: "ALL",
                         options: ["ALL","UNPROCESSED", "ERROR EXTRACTING JSON", "EXTRACTING JSON", "JSON EXTRACTED ", "TAGGED"],
                         total: 0,
                    },
                    searchQuery: "",

                    searchedData: [],
                    fuseSearch: null,
               };
          },
          methods: {
               processError(errorMessage) {
                    this.$notify({
                         message: errorMessage,
                         timeout: 3000,
                         icon: "tim-icons icon-bell-55",
                         horizontalAlign: "right",
                         verticalAlign: "bottom",
                         type: "danger",
                    });
               },
               async downloadTaggedJSON(fileName) {
                    try {
                         const response = await axios.post(
                              "https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController",
                              {
                                   type: "GETOBJ",
                                   fileName: `uploads/${fileName}`,
                              },
                              {
                                   headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json;charset=UTF-8",
                                   },
                              }
                         );

                         if (!response || response.status !== 200) {
                              this.processError("There was an error fetching file", e);
                              return;
                         }

                         let url = response.data.fileUrl;
                         window.open(url, "_blank");
                    } catch (e) {
                         this.processError("There was an error fetching file");
                         console.log(e);
                    }
               },

               async downloadOriginalPPT(fileName) {
                    try {
                         const response = await axios.post(
                              "https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController",
                              {
                                   type: "GETOBJ",
                                   fileName: `uploads/${fileName}`,
                              },
                              {
                                   headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json;charset=UTF-8",
                                   },
                              }
                         );

                         if (!response || response.status !== 200) {
                              this.processError("There was an error fetching file", e);
                              return;
                         }

                         let url = response.data.fileUrl;
                         window.open(url, "_blank");
                    } catch (e) {
                         this.processError("There was an error fetching file");
                         console.log(e);
                    }
               },

               // async downloadPPT(file) {
               //      try {
               //           const response = await axios.post(
               //                "https://34o5fue5wg.execute-api.ap-south-1.amazonaws.com/default/storageController",
               //                {
               //                     type: "GET",
               //                     pptName: "",
               //                },
               //                {
               //                     headers: {
               //                          Accept: "application/json",
               //                          "Content-Type": "application/json;charset=UTF-8",
               //                     },
               //                }
               //           );

               //           if (!response || response.status !== 200) {
               //                this.processError("There was an error fetching file", e);
               //                return;
               //           }

               //           let url = response.data.pptUrl;
               //           window.open(url, "_blank");
               //      } catch (e) {
               //           this.processError("There was an error fetching file");
               //           console.log(e);
               //      }
               // },
               tag(key) {
                    console.log(key);

                    this.$router.push();
               },
               handleLike(index, row) {
                    swal.fire({
                         title: `You liked ${row.name}`,
                         buttonsStyling: false,
                         icon: "success",
                         customClass: {
                              confirmButton: "btn btn-success btn-fill",
                         },
                    });
               },
          },

          filters: {
               toAgo(x) {
                    let d = x;
                    let seconds = Math.floor((new Date() - d) / 1000);
                    let interval = seconds / 31536000;
                    if (interval > 1) {
                         let rounded = Math.floor(interval);
                         if (rounded == 1) {
                              return rounded + " year";
                         }
                         return rounded + " years";
                    }
                    interval = seconds / 2592000;
                    if (interval > 1) {
                         let rounded = Math.floor(interval);
                         if (rounded == 1) {
                              return rounded + " month";
                         }
                         return rounded + " months";
                    }
                    interval = seconds / 86400;
                    if (interval > 1) {
                         let rounded = Math.floor(interval);
                         if (rounded == 1) {
                              return rounded + " day";
                         }
                         return rounded + " days";
                    }
                    interval = seconds / 3600;
                    if (interval > 1) {
                         let rounded = Math.floor(interval);
                         if (rounded == 1) {
                              return rounded + " hour";
                         }
                         return rounded + " hours";
                    }
                    interval = seconds / 60;
                    if (interval > 1) {
                         return Math.floor(interval) + " minutes";
                    }
                    return Math.floor(seconds) + " seconds";
               },
          },
          mounted() {
               // Fuse search initialization.
               this.fuseSearch = new Fuse(this.tableData, {
                    keys: ["userEmail", "filename"],
                    threshold: 0.3,
               });
          },
          watch: {
               /**
                * Searches through the table data by a given query.
                * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
                * @param value of the query
                */
               searchQuery(value) {
                    console.log(value);
                    let result = this.tableData;
                    if (value !== "") {
                         result = this.fuseSearch.search(this.searchQuery).map((item) => {
                              return item.item;
                         });
                         console.log(result);
                    }
                    this.searchedData = result;
               },
          },
     };
</script>
<style>
     .pagination-select,
     .search-input {
          width: 200px;
     }
     .swal2-icon-content {
          font-size: inherit !important;
     }

     .el-tag {
          color: black;
     }
     .el-tag--success {
          color: black !important;
     }
     .el-tag--info {
          color: black !important;
     }
     .el-tag--warning {
          color: black !important;
     }
</style>
