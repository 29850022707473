var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"CT","title":"Creative Tim"},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
                         name: 'Dashboard',
                         icon: 'tim-icons icon-chart-pie-36',
                         path: '/dashboard',
                    }}}),_c('sidebar-item',{attrs:{"link":{
                         name: 'Upload',
                         icon: 'tim-icons icon-cloud-upload-94',
                         path: '/upload',
                    }}}),_c('sidebar-item',{attrs:{"link":{
                         name: 'Repository',
                         icon: 'tim-icons icon-single-copy-04',
                         path: '/repo',
                    }}})]}}])}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }