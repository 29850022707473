import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "./../pages/Starter/SampleLayout.vue";
import AuthLayout from "src/pages/Pages/AuthLayout.vue";
// import Starter from "./../pages/Starter/SamplePage.vue";
import Dashboard from "./../pages/Starter/Dashboard.vue";
import Repo from "./../pages/Starter/Repo.vue";
import Upload from "./../pages/Starter/Upload.vue";
import Tag from "./../pages/Starter/Tag.vue";
import test from "./../pages/Starter/Blank.vue";
import store from "../store/store.js";
import login from "./../pages/Starter/Login.vue";
Vue.use(Router);

let router = new Router({
     routes: [
          {
               path: "/",
               name: "home",
               redirect: "/dashboard",
               component: DashboardLayout,
               children: [
                    {
                         path: "dashboard",
                         name: "dashboard",
                         components: { default: Dashboard },
                         meta: { auth: true },
                    },
                    {
                         path: "upload",
                         name: "upload",
                         components: { default: Upload },
                         meta: { auth: true },
                    },
                    {
                         path: "repo",
                         name: "repository",
                         components: { default: Repo },
                         meta: { auth: true },
                    },
                    {
                         path: "tag",
                         name: "TAG",
                         components: { default: Tag },
                         params: true,
                         meta: { auth: true },
                    },

                    {
                         path: "test",
                         name: "test",
                         components: { default: test },
                         params: true,
                         meta: { auth: true },
                    },
               ],
          },
          {
               path: "/login",
               component: AuthLayout,
               name: "Authentication",
               children: [
                    {
                         path: "/login",
                         name: "login",
                         component: login,
                         meta: { auth: false },
                    },
               ],
          },
     ],
     mode: "history",
     linkActiveClass: "active",
     scrollBehavior(to, from, savedPosition) {
          if (savedPosition) {
               return savedPosition;
          } else {
               return { x: 0, y: 0 };
          }
     },
});

router.beforeEach((to, from, next) => {
     if (to.meta.auth) {
          if (!store.state.auth.loggedIn) {
               next({ name: "login", params: { next: to.name , query : to.query} });
          } else {
               next();
          }
     } else {
          next();
     }
});

export default router;
