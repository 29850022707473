/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import store from "./store/store";
import "./assets/customStyles.scss";
// router setup
import router from "./routes/starterRouter";
// import i18n from "./i18n";
// import "./registerServiceWorker";
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

/* eslint-disable no-new */
new Vue({
     el: "#app",
     render: (h) => h(App),
     router,
     store,
     //  i18n,
});

// let app;
// Firebase.auth.onAuthStateChanged(() => {
//      console.log("auth changed");
//      if (!app) {
//           app = new Vue({
//                router,
//                store,
//                render: (h) => h(App),
//           }).$mount("#app");
//      }
// });
