<template>
     <div>
          <div v-if="!authUpdated" class="loader">
               <div class="spinner" style="margin:10px">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
               </div>
          </div>

          <div v-else>
               <router-view></router-view>
          </div>
     </div>
</template>

<script>
     import Firebase from "./firebase.js";
     export default {
          data() {
               return {
                    authUpdated: false,
               };
          },
          methods: {},
          mounted() {},

          created() {
               Firebase.auth.onAuthStateChanged(async (user) => {
                    if (user) {
                         let querySnapshot = await Firebase.adminCollection.where("email", "==", user.email).get();

                         querySnapshot.forEach((doc) => {
                              //update login
                              console.log("logged in");

                              this.$store.state.auth.loggedIn = true;
                              this.$store.state.auth.UID = doc.id;
                              this.$store.state.auth.name = user.displayName;
                              this.$store.state.auth.email = user.email;
                         });
                    } else {
                         this.$router.push({ name: "login" });
                    }

                    this.authUpdated = true;
               });
          },
     };
</script>
<style lang="scss" scoped>
     .loader {
          display: block;
          margin: 100px auto;
          text-align: center;
          width: 100px;
     }
</style>
