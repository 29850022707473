<template>
     <div class="container">
          <div class="col-lg-4 col-md-6 ml-auto mr-auto">
               <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(submit)">
                         <card class="card-login card-white">
                              <template slot="header" style="padding-bottom:10px;">
                                   <!-- <img src="img/card-primary.png" alt="" /> -->
                                   <h1 class="card-title" style="color:coral;padding:20px;">Log in</h1>
                              </template>

                              <div>
                                   <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
                                        Login with Gmail
                                   </base-button>
                              </div>
                         </card>
                    </form>
               </ValidationObserver>
          </div>
     </div>
</template>
<script>
     import Firebase from "../../firebase.js";
     import { extend } from "vee-validate";
     import { required, email, min } from "vee-validate/dist/rules";

     extend("email", email);
     extend("min", min);

     extend("required", required);

     export default {
          data() {
               return {
                    email: "",
                    password: "",
                    subscribe: true,
               };
          },
          computed: {
               isLoggedIn() {
                    return this.$store.state.auth.loggedIn;
               },
          },

          watch: {
               isLoggedIn() {
                    this.redirectUserIfLoggedIn();
               },
          },
          created() {
               this.redirectUserIfLoggedIn();
          },
          methods: {
               redirectUserIfLoggedIn() {
                    if (this.$store.state.auth.loggedIn) {
                         if (this.$route.params.next) {
                              this.$router.push({ name: this.$route.params.next, query: this.$route.params.query });
                         } else {
                              this.$router.push({ name: "dashboard" });
                         }
                    }
               },
               async submit() {
                    await Firebase.login();

                    this.redirectUserIfLoggedIn();
               },
          },
     };
</script>
<style>
     .navbar-nav .nav-item p {
          line-height: inherit;
          margin-left: 5px;
     }
</style>
